import React, { useState, useEffect, useMemo, useRef } from "react";
import { Col, Skeleton } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import ThemeInput from "../ThemeInput";
import { SearchOutlined } from "@ant-design/icons";
import { Document, Page, Text, View, pdf } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { setEntry } from "../../store/slice/dataFilterSlice";
import { saveAs } from "file-saver";
import { formatToINR } from "../FormateToINR/formatToINR";
import {
  FileTypeButton,
  StyledDiv,
  StyledRow,
  StyledSummarySearchInput,
  styles,
  FooterRow,
  Summaycolumns,
  itemOption,
  TableTitleCol,
  TableTitleSearch,
  StyTable,
} from "./style";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";

const convertToCSV = (data, columns) => {
  const header = columns.map((col) => col.title).join(",");
  const rows = data.map((row, index) => {
    return columns
      .map((col) => {
        let value;
        if (col.dataIndex === "srNo") {
          value = index + 1; // Handle SR NO. separately as it is index based
        } else {
          value = row[col.dataIndex];
          if (col.dataIndex === "userId") {
            value =
              row.userId === "Total"
                ? `${row.userId}`
                : `${row.userId} (${row.AccountName})`;
          } else if (col.dataIndex === "valan") {
            value = value?.valanName || "";
          } else {
            value = value !== null && value !== undefined ? value : "";
          }
        }
        return value;
      })
      .join(",");
  });
  return [header, ...rows].join("\n");
};
const calculateTotals = (data, filterType) => {
  if (filterType !== "summary") {
    return;
  }
  const totals = {};
  Summaycolumns?.forEach?.((col) => {
    if (
      col.dataIndex !== "srNo" &&
      col.dataIndex !== "userId" &&
      col.dataIndex !== "valan"
    ) {
      totals[col.dataIndex] = data?.reduce?.((acc, row) => {
        const value = row[col.dataIndex];
        return (
          acc + (value !== null && value !== undefined ? parseFloat(value) : 0)
        );
      }, 0);
    }
  });
  return totals;
};
const totalValan = (data, name) => {
  if (data) {
    const total = data?.reduce?.((acc, item) => {
      return (acc += item?.[name] || 0);
    }, 0);
    return formatToINR(total || 0);
  }
};
const footerComponents = (filteredData) => (
  <FooterRow>
    <td colSpan="2">Total :</td>
    <td></td>
    <td>{totalValan(filteredData, "totalNetAmount")}</td>
    <td></td>
    <td>{totalValan(filteredData, "withoutBrokerage")}</td>
    <td>{totalValan(filteredData, "brokerage")}</td>
    <td>{totalValan(filteredData, "downlinem2m")}</td>
    <td>{totalValan(filteredData, "uplinem2m")}</td>
    <td>{totalValan(filteredData, "selfm2m")}</td>
  </FooterRow>
);
const BrokerageFooter = (filteredData) => (
  <FooterRow>
    <td colSpan="2">Total :</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>{totalValan(filteredData, "totalBrokerage")}</td>
    <td>{totalValan(filteredData, "myBrokerage")}</td>
    <td>{totalValan(filteredData, "uplineBrokerage")}</td>
    <td>{totalValan(filteredData, "downlineBrokerage")}</td>
    <td></td>
    <td></td>
    <td></td>
  </FooterRow>
);
const checkEntry = (value) => {
  if (
    value == "pnl" ||
    value === "brokerage" ||
    value === "trade" ||
    value === "tradeLog" ||
    value === "rejectionLog" ||
    value === "m2m"
  ) {
    return true;
  } else {
    return false;
  }
};
const filterConfigs = {
  trade: (item, value) => {
    const customer = item?.customer?.[0];
    const tradeBy = item?.tradeBy?.[0];
    return (
      customer?.accountName?.toLowerCase().includes(value) ||
      customer?.userId?.toString().includes(value) ||
      item?.deviceType?.toLowerCase()?.toString().includes(value) ||
      item?.marketName?.toLowerCase()?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value) ||
      item?.tradeStatus?.toLowerCase()?.toString().includes(value) ||
      tradeBy?.userId?.toString().includes(value) ||
      tradeBy?.accountName?.toLowerCase()?.toString().includes(value)
    );
  },
  summary: (item, value) => {
    return (
      item.user?.accountName?.toLowerCase().includes(value) ||
      item.user?.userId?.toString().includes(value)
    );
  },
  tradeLog: (item, value) => {
    const client = item?.client;
    return (
      client?.accountName?.toLowerCase().includes(value) ||
      client?.userId?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value)
    );
  },
  rejectionLog: (item, value) => {
    const client = item?.client;
    const message = item?.message;
    return (
      client?.accountName?.toLowerCase().includes(value) ||
      client?.userId?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value) ||
      message?.code?.toString().includes(value)
    );
  },
  m2m: (item, value) => {
    const data = item?.data;
    return (
      data?.userName?.toLowerCase()?.toString()?.includes(value) ||
      data?.message?.toLowerCase()?.toString()?.includes(value) ||
      item?.clientId?.toString()?.includes(value)
    );
  },
  brokerage: (item, value) => {
    return (
      item?.clientName?.toLowerCase()?.toString()?.includes(value) ||
      item?.client?.toString()?.includes(value) ||
      item?.symbolName?.toLowerCase()?.toString()?.includes(value) ||
      item?.title?.toLowerCase()?.toString()?.includes(value) ||
      item?.tradeType?.toLowerCase()?.toString()?.includes(value)
    );
  },
  pnl: (item, value) => {
    return (
      item?.userId?.toString()?.includes(value) ||
      item?.accountName?.toLowerCase()?.toString()?.includes(value)
    );
  },
  blockScript: (item, value) => {
    return item?.Script?.toLowerCase()?.toString()?.includes(value);
  },
};
function ThemeCardTable({
  buttonType,
  buttonTitle,
  column,
  data,
  isLoading,
  filterType,
  footer,
}) {
  // state
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const columnKey = column?.[0]?.dataIndex;
  const issummary = filterType === "summary";
  const totals = useMemo(
    () => calculateTotals(filteredData, filterType),
    [filteredData]
  );
  const { params, setSearchParams, handleDeleteWithAdd } = useGetQueryParams();

  //redux
  const entries = useSelector(
    (state) => state?.dataFilterSlice?.entriesPerPage
  );

  // life cycle
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // function
  const handleExportCSV = () => {
    const dataWithTotals = [...filteredData, { ...totals, userId: "Total" }];
    const csv = convertToCSV(dataWithTotals, Summaycolumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${filterType}_data.csv`);
  };

  const handleTradeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const config = filterConfigs?.[filterType];
    if (!config) {
      // console.warn(`Invalid filter type: ${filterType}`);
      return;
    }

    if (value === "") {
      setFilteredData(data);
      return;
    }

    const filterFunction = filterConfigs[filterType];
    if (filterFunction) {
      const searchedData = data?.filter((item) => filterFunction(item, value));
      setFilteredData(searchedData);
    }
  };

  // useDebounce(
  //   () => {
  //     if (search) {
  //       setSearchParams({ ...params, search: search });
  //     } else if (params?.search) {
  //       handleDeleteWithAdd("search", null);
  //     }
  //   },
  //   300,
  //   [search]
  // );

  const handleChange = (value) => {
    if (value) {
      let isSet = checkEntry(filterType);
      if (isSet) {
        setSearchParams({ ...params, page: 1, limit: value?.[0] });
      }
      dispatch(setEntry(value[0]));
    }
  };

  const handleDownload = (title, key) => {
    if (title === "csv") {
      handleExportCSV();
    } else if (title === "pdf") {
      const dataWithTotals = [...filteredData, { ...totals, userId: "Total" }];
      generatePDF(dataWithTotals);
    } else {
      let objToAdd =
        title === "all"
          ? null
          : title === "limit" || title === "intradaylimit"
          ? { [key]: title, tradeStatus: "executed" }
          : { [key]: title };
      let removeKey =
        title === "all"
          ? ["title", "tradeStatus"]
          : key === "title"
          ? "tradeStatus"
          : "title";
      handleDeleteWithAdd(removeKey, objToAdd);
    }
  };

  const skeletonData = new Array(5).fill({}).map((_, index) => ({
    // key: index,
    [columnKey]: (
      <Skeleton.Input
        style={{ width: `${(index + 1) * 15}rem`, height: "1.6rem" }}
        active={isLoading}
      />
    ),
  }));

  const SkeletonLoading = () => {
    return (
      <div className="account-table-skeleton">
        {new Array(3).fill({}).map((el, index) => {
          return (
            <Skeleton.Input
              key={index}
              active={true}
              style={{ width: `${(index + 1) * 15}rem`, height: "1.6rem" }}
            ></Skeleton.Input>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
        borderRadius: "10px",
        background: "#fff",
        paddingTop: "1rem",
      }}
    >
      <StyledRow gutter={[10, 10]} justify={"space-between"}>
        <TableTitleCol xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
          <StyledDiv>Show Entries</StyledDiv>
          <ThemeDropDown
            placeholder="Select"
            width={"150%"}
            required
            value={checkEntry(filterType) ? params?.limit : entries}
            onChange={handleChange}
            options={itemOption}
          />
        </TableTitleCol>

        {buttonType &&
          buttonTitle.map((title, index) => (
            <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
              <FileTypeButton
                key={index}
                type="primary"
                onClick={() => handleDownload(title?.value, title.key)}
                style={{
                  backgroundColor:
                    params?.title === title?.value ||
                    params?.tradeStatus === title?.value ||
                    (title?.value === "all" &&
                      !params?.title &&
                      !params?.tradeStatus)
                      ? "#728485"
                      : "",
                }}
              >
                {title?.label}
              </FileTypeButton>
            </Col>
          ))}

        <TableTitleSearch
          xxl={4}
          xl={4}
          lg={6}
          md={8}
          sm={12}
          xs={12}
          filterType={filterType}
        >
          <StyledSummarySearchInput>
            <ThemeInput
              wrapperwidth={"100%"}
              inputProps={{
                placeholder: "Search anything here",
                prefix: <SearchOutlined />,
                style: { width: "100%" },
                onChange: handleTradeSearch,
              }}
              formProps={{
                name: "search_text",
              }}
            />
          </StyledSummarySearchInput>
        </TableTitleSearch>
      </StyledRow>
      <div style={{ marginTop: "1rem" }}>
        <StyTable
          className="userTable"
          rowKey="_id"
          columns={column}
          dataSource={filteredData}
          pagination={false}
          loading={{
            spinning: isLoading ? true : false,
            indicator: <SkeletonLoading />,
          }}
          sticky
          scroll={{
            x: 1300,
          }}
          summary={
            (filterType !== "summary" && filterType !== "brokerage") ||
            filteredData?.length == 0 ||
            isLoading ||
            !footer
              ? null
              : issummary
              ? () => footerComponents(filteredData)
              : () => BrokerageFooter(filteredData)
          }
        />
      </div>
    </div>
  );
}

export default ThemeCardTable;

const generatePDF = async (dataWithTotals) => {
  const pdfData = await pdf(
    <Document>
      <Page size="A2" style={styles.page}>
        {/* Render your table rows here */}
        <View style={styles.text}>
          <Text>Summary Report</Text>
        </View>
        <View>
          <View style={styles.scriptHeader}>
            {Summaycolumns.map((col, index) => (
              <Text style={styles.tableCell} key={index}>
                {col.title}
              </Text>
            ))}
          </View>
          {dataWithTotals?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              {Summaycolumns.map((col, colIndex) => (
                <Text style={styles.tableCell} key={colIndex}>
                  {col.render
                    ? col.render(item[col.dataIndex], item, index)
                    : item[col.dataIndex]}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  ).toBlob();

  const pdfUrl = URL.createObjectURL(pdfData);
  const downloadLink = document.createElement("a");
  downloadLink.href = pdfUrl;
  downloadLink.download = "summary_data.pdf";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
